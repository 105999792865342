import { Component } from 'react';
import Select from 'react-select';
import BookList from './components/book-list/book-list.component';
import './App.css';

class App extends Component {
  constructor () {
    super();

    this.state = {
      genres: [],
      books: [],
      selectedGenre: {
        value: 'classics',
        label: 'classics'
      },
      jsonNum: 0,
      showLoadMore: false,
    };
  }

  componentDidMount() {
    this.fetchGenres();
    this.fetchBooks('classics');
  }

  fetchGenres = () => {
    const prefix = 'https://raw.githubusercontent.com/dchooyc/genre/main/';
    const url = prefix + 'genres.json';
    fetch(url)
      .then(response => response.json())
      .then(data => this.setState({
        genres: data.genres.map(genre => ({
          value: genre,
          label: genre
        }))
      }));
  }

  fetchBooks = (genre, increment = false) => {
    const nextJsonNum = increment ? this.state.jsonNum + 1 : this.state.jsonNum;
    const prefix = 'https://raw.githubusercontent.com/dchooyc/genre/main/jsons/';
    const postfix = genre + '/' + genre + String(nextJsonNum) + '.json';
    const url = prefix + postfix;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        const newBooks = increment ? [...this.state.books, ...data.books] : data.books;
        this.setState({
          books: newBooks,
          jsonNum: nextJsonNum,
          showLoadMore: true,
        });
      });
  }

  loadMoreBooks = () => {
    this.fetchBooks(this.state.selectedGenre.value, true);
  }

  handleGenreChange = (selectedOption) => {
    this.setState(
      {
        selectedGenre: selectedOption,
        books: [],
        jsonNum: 0,
        showLoadMore: false,
      },
      () => this.fetchBooks(selectedOption.value)
    )
  }


  render () {
    const { books, genres, selectedGenre, showLoadMore } = this.state;
    const { handleGenreChange, loadMoreBooks } = this;
    const customStyles = {
      control: (provided) => ({
        ...provided,
        backgroundColor: '#E4D7C5',
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#A58F86' : '#E4D7C5',
        color: state.isSelected ? '#E4D7C5' : '#000',
        '&:hover': {
          backgroundColor: '#BFA295',
          color: '#F0E6DC',
        },
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: '#E4D7C5',
      }),
    };

    return (
      <div className="App">
        <div
          className='select-box-holder'
        >
          <Select
            className='select-box'
            value={selectedGenre}
            onChange={handleGenreChange}
            options={genres}
            isSearchable={true}
            placeholder={"Select a genre.."}
            styles={customStyles}
          />
        </div>
        <BookList books={ books }/>
        { showLoadMore && (
        <button
          className='load-more-button'
          onClick={loadMoreBooks}
        >
          Load More
        </button>
        )
        }
      </div>
    );
  }
}

export default App;
