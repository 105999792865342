import React, { Component } from "react";

import './book-img.styles.css';

class BookImage extends Component {
    constructor(props) {
        super(props);
        this.state = { isVisible: false };
        this.imgRef = React.createRef();
    }

    componentDidMount() {
        this.observer = new IntersectionObserver(
            (entries) => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                this.setState({ isVisible: true });
                this.observer.unobserve(this.imgRef.current);
            }
            },
            {
            rootMargin: '100px',
            }
        );
    
        if (this.imgRef.current) {
          this.observer.observe(this.imgRef.current);
        }
    }
    
    componentWillUnmount() {
        if (this.imgRef.current) {
            this.observer.unobserve(this.imgRef.current);
        }
    }

    render () {
        const { title, cover_url } = this.props;
        const { isVisible } = this.state;
        const { imgRef } = this;

        return (
            <img
            ref={imgRef}
            className='book-img'
            alt={`book ${title}`}
            src={isVisible ? cover_url : cover_url}
            >
            </img>
        );
    }
}

export default BookImage;