import { Component } from 'react';

import BookSearchItem from '../book-search-item/book-search-item.component';

import './book-search.styles.css';

class BookSearch extends Component {
    amazonSearch = (title) => {
        const prefix = 'https://www.amazon.com/s?k=';
        let words = title.replace(/[^\w\s]/g, ' ').split(/\s+/);

        if (words.length > 5) {
            words = words.slice(0, 5);
        }

        return prefix + words.join('+')
    }

    nlbSearch = (title) => {
        const prefix = 'https://search.nlb.gov.sg/onesearch/Search?query=';
        const postfix = '&cont=book';
        let words = title.replace(/[^\w\s]/g, ' ').split(/\s+/);

        if (words.length > 5) {
            words = words.slice(0, 5);
        }

        return prefix + words.join('+') + postfix
    }

    blackwellSearch = (title) => {
        const prefix = 'https://blackwells.co.uk/bookshop/search/?keyword=';
        let words = title.replace(/[^\w\s]/g, ' ').split(/\s+/);

        if (words.length > 5) {
            words = words.slice(0, 5);
        }

        return prefix + words.join('+')
    }

    barnesSearch = (title) => {
        const prefix = 'https://www.barnesandnoble.com/s/';
        let words = title.replace(/[^\w\s]/g, ' ').split(/\s+/);

        if (words.length > 5) {
            words = words.slice(0, 5);
        }

        return prefix + words.join('%20')
    }

    carousellSearch = (title) => {
        const prefix = 'https://www.carousell.sg/search/';
        let words = title.replace(/[^\w\s]/g, ' ').split(/\s+/);

        if (words.length > 5) {
            words = words.slice(0, 5);
        }

        return prefix + words.join('%20')
    }

    ebaySearch = (title) => {
        const prefix = 'https://www.ebay.com/sch/i.html?_from=R40&_nkw=';
        let words = title.replace(/[^\w\s]/g, ' ').split(/\s+/);

        if (words.length > 5) {
            words = words.slice(0, 5);
        }

        return prefix + words.join('+')
    }

    audibleSearch = (title) => {
        const prefix = 'https://www.audible.com/search?keywords=';
        let words = title.replace(/[^\w\s]/g, ' ').split(/\s+/);

        if (words.length > 5) {
            words = words.slice(0, 5);
        }

        return prefix + words.join('+')
    }

    openSearch = (title) => {
        const prefix = 'https://openlibrary.org/search?q=';
        const postfix = '&mode=everything';
        let words = title.replace(/[^\w\s]/g, ' ').split(/\s+/);

        if (words.length > 5) {
            words = words.slice(0, 5);
        }

        return prefix + words.join('+') + postfix
    }

    render() {
        const { title } = this.props;
        const { nlbSearch, amazonSearch, blackwellSearch, barnesSearch, carousellSearch, ebaySearch, audibleSearch, openSearch } = this;

        return (
            <div className='book-search'>
                <BookSearchItem title={title} url={amazonSearch(title)} icon={'/icons/amazon.png'} />
                <BookSearchItem title={title} url={blackwellSearch(title)} icon={'/icons/blackwell.png'} />
                <BookSearchItem title={title} url={barnesSearch(title)} icon={'/icons/barnes.png'} />
                <BookSearchItem title={title} url={carousellSearch(title)} icon={'/icons/carousell.png'} />
                <BookSearchItem title={title} url={ebaySearch(title)} icon={'/icons/ebay.png'} />
                <BookSearchItem title={title} url={audibleSearch(title)} icon={'/icons/audible.png'} />
                <BookSearchItem title={title} url={openSearch(title)} icon={'/icons/open.png'} />
                <BookSearchItem title={title} url={nlbSearch(title)} icon={'/icons/nlb.png'} />
            </div>
        );
    }
}

export default BookSearch;