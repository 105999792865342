import { Component } from 'react';

import './book-list.styles.css';
import Book from '../book/book.component';

class BookList extends Component {
    render() {
        const { books } = this.props;

        return (
            <div className='book-list'>
                {
                books.map((book) => {
                    return <Book key = { book.id } book = { book } />
                })
                }
            </div>
        );
    }
}

export default BookList;